import { Link } from 'gatsby';
import * as React from 'react';
import { NewAppTitle, NewAppText, NewAppButton } from '../components/ui';
import { mobileSize } from '../utils';
import styled from 'styled-components';
import { IndexLayout } from '../layouts/';
import Helmet from 'react-helmet';
import { NavBar } from '../components/segments/NavBar';
import DarkFooter from '../components/segments/DarkFooter';
import { theme } from '../utils/theme';
import { desktopSize, laptopSize } from '../utils/variables';
import approachIcon1 from '../images/approachicon1.svg';
import approachIcon2 from '../images/approachicon2.svg';
import LetsConnectSection from '../components/segments/LetsConnectSection';
import scrollTo from 'gatsby-plugin-smoothscroll';


import ProductJourney from '../components/segments/ProductJourney';
import DevProcess from '../components/segments/DevProcess';
import OurWorkPreview from '../components/segments/OurWorkPreview';



export default () => {
  return (
    <IndexLayout>
      <Helmet>
        <title>Rapptr Labs | Approach</title>
        <link rel="stylesheet" href="https://use.typekit.net/yxm3bov.css" />
      </Helmet>
      <NavBar alwaysOpaque invert />
      <PageContainer>
        <TextContainer>
          {/* <Wrapper> */}
          {/* <PageLabel>404 Page</PageLabel> */}
          <PageMainText>
            A <span style={{ color: theme.SKY_BLUE }}>/Refreshingly/</span> Rapptr Approach
          </PageMainText>
          <PageDescriptionText>
            We empower everyone from established brands to funded start-ups with a fast, flexible,
            and agile path to launching their digital products.
          </PageDescriptionText>
          <ButtonsDiv>
            <ApproachButton onClick={() => scrollTo('#product-journey')}>
              <img src={approachIcon1} /> &nbsp;Product Journey Approach
            </ApproachButton>
            <ApproachButton onClick={() => scrollTo('#agile-development')}>
              <img src={approachIcon2} /> &nbsp;Agile Development
            </ApproachButton>
          </ButtonsDiv>
        </TextContainer>
        {/* </Wrapper> */}
        <AbsDiv1 />
        <AbsDiv2 />
      </PageContainer>
      <ProductJourney />
      <LetsConnectDiv>
        <TextContainer>
          <LetsConnectText>Interested? Let’s Discuss Your Product’s Journey</LetsConnectText>
          <CenterDiv>
            <Link to="/contact-us/">
              <ContactUsButton>Contact Us</ContactUsButton>
            </Link>
          </CenterDiv>
        </TextContainer>
      </LetsConnectDiv>
      <DevProcess/>
      <OurWorkPreview />
      <LetsConnectSection />
      <DarkFooter />
    </IndexLayout>
  );
};

const ApproachButton = styled(NewAppButton)`
  width: fit-content;
  @media ${mobileSize} {
    line-height: 1.2;
    min-height: fit-content;
    height: fit-content;
    max-height: fit-content;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
  }
`;

const ButtonsDiv = styled.div`
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 39px;
  margin: 42px auto 0px auto;
  @media ${mobileSize} {
    grid-template-columns: 1fr;
  }
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ContactUsButton = styled(NewAppButton)`
  margin: 0px;
  padding: 10px 12px;
  line-height: 1;
  height: 40px;
  background-color: ${theme.MAIN_DARK_BLUE};
  color: ${theme.WHITE_COLOR};
  border-radius: 7px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

const LetsConnectText = styled(NewAppTitle)`
  font-weight: 700;
  font-size: 42px;
  line-height: 55px;
  text-align: center;
  @media ${mobileSize} {
    font-size: 35px;
    line-height: 45px;
  }
`;

const PageDescriptionText = styled(NewAppText)`
  max-width: 880px;
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
  margin-bottom: 60px;
  text-align: center;
  margin: 0px auto;
`;

const PageMainText = styled(NewAppTitle)`
  /* font-family: 'Objektiv Mk2'; */
  font-style: normal;
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  margin-bottom: 39px;
  /* max-width: 694px; */
  text-align: center;
  @media ${mobileSize} {
    font-size: 36px;
    line-height: 44px;
    /* white-space: pre-wrap; */
  }
`;

const AbsDiv1 = styled.div`
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  /* border-width: 150px 0 0 27vw; */
  border-width: 150px 25vw 0 0;
  border-color: ${theme.BACKGROUND_COLOR} transparent transparent transparent;
  bottom: -149px;
  left: 0;
  z-index: 3;
  @media ${desktopSize} {
    border-width: 100px 25vw 0 0;
    bottom: -100px;
  }
  @media ${laptopSize} {
    display: none;
  }
`;

const AbsDiv2 = styled.div`
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 75vw 190px 0;
  border-color: transparent ${theme.BACKGROUND_COLOR} transparent transparent;
  bottom: -189px;
  right: 0;
  z-index: 3;
  @media ${desktopSize} {
    border-width: 0 75vw 140px 0;
    bottom: -139px;
  }
  @media ${laptopSize} {
    border-width: 0 100vw 70px 0;
    bottom: -69px;
  }
`;

const TextContainer = styled.div`
  /* width: 835px; */
  width: fit-content;
  z-index: 2;
  @media ${laptopSize} {
    width: 100%;
  }
`;

const PageContainer = styled.div`
  position: relative;
  min-height: 500px;
  width: 100%;
  background-color: ${theme.BACKGROUND_COLOR};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  margin-top: 120px;
  @media ${laptopSize} {
    margin-top: 80px;
  }
`;

const LetsConnectDiv = styled.div`
  background-color: ${theme.MID_BLUE};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 62px 20px;
  z-index: 1;
`;
