import React from 'react';
import styled, { keyframes } from 'styled-components';
import imgStep1 from '../../images/approach/Desktop - Agile - Step 1.svg';
import imgStep2 from '../../images/approach/Desktop - Agile - Step 2.svg';
import imgStep3 from '../../images/approach/Desktop - Agile - Step 3.svg';
import imgStep4 from '../../images/approach/Desktop - Agile - Step 4.svg';
import imgStep5 from '../../images/approach/Desktop - Agile - Step 5.svg';
import imgStep6 from '../../images/approach/Desktop - Agile - Step 6.svg';

interface ProcessChartProps {
  step: number;
  prevStep: number;
}

export const ProcessGraphic: React.FC<ProcessChartProps> = ({ step, prevStep }) => {
  const returnStepImage = (step: number) => {
    switch (step) {
      case 1:
        return imgStep1;
      case 2:
        return imgStep2;
      case 3:
        return imgStep3;
      case 4:
        return imgStep4;
      case 5:
        return imgStep5;
      case 6:
        return imgStep6;
      default:
        break;
    }
  };

  return (
    <Container>
      {
        [1, 2, 3, 4, 5, 6].map((idx: number) => <Img key={`process-image-${idx}`} src={returnStepImage(idx)} alt={`Our approach: step ${idx}`} visible={step === idx} />)
      }
      {
        [1, 2, 3, 4, 5, 6].map((idx: number) => <FadeImg key={`prev-process-image-${idx}`} src={returnStepImage(idx)} alt={`Our approach: step ${idx}`} fade={prevStep === idx} />)
      }
      
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  @media screen and (max-width: 1024px) {
    min-width: 325px;
    width: 50%;
    aspect-ratio: 1/1;
    margin: 4px auto 32px auto;
  }
`;

const Img = styled.img<{ visible: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
  opacity: ${props => props.visible ? 1 : 0};
  transition: opacity 0.2s ease-in-out;
`;

const fade = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const FadeImg = styled.img<{ fade: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
  opacity: 0;
  animation: ${props => props.fade ? fade : null} 0.2s ease-in-out forwards;
  /* opacity: ${props => props.fade ? 1 : 0}; */
  /* transition: opacity 0.2s ease-in-out; */
`;
