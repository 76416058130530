import React, { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import { desktopSize, laptopSize, mobileSize } from '../../utils';
import { theme } from '../../utils/theme';
import { NewAppText, NewAppTitle } from '../ui';
import numberHexagon from '../../images/numberhexagon.svg';
import { ApproachGraphic } from './ApproachGraphic';
import { NewApproachGraphic } from './NewApproachGraphic';

export default function ProductJourney() {
  const [journeyStep, setJourneyStep] = useState(1);
  const [previousJourneyStep, setPreviousJourneyStep] = useState(1);

  const { ref: journeyRef1, inView: journeyInView1 } = useInView();
  const { ref: journeyRef2, inView: journeyInView2 } = useInView();
  const { ref: journeyRef3, inView: journeyInView3 } = useInView();
  const { ref: journeyRef4, inView: journeyInView4 } = useInView();

  useEffect(() => {
    if (journeyInView1) {
      setPreviousJourneyStep(journeyStep);
      setJourneyStep(1);
    }
    if (journeyInView2) {
      setPreviousJourneyStep(journeyStep);
      setJourneyStep(2);
    }
    if (journeyInView3) {
      setPreviousJourneyStep(journeyStep);
      setJourneyStep(3);
    }
    if (journeyInView4) {
      setPreviousJourneyStep(journeyStep);
      setJourneyStep(4);
    }
  }, [journeyInView1, journeyInView2, journeyInView3, journeyInView4]);

  const journeyStepsTexts = [
    {
      id: 1,
      title: 'Strategy & Design',
      description: [
        'Market research, wireframing, prototyping, and user testing to determine product viability and create a roadmap to bring us to market.',
        'Our approach helps validate your concept quickly and effectively and builds trust. This can be conducted as a standalone engagement with no further commitment required.',
      ],
      ref: journeyRef1,
    },
    {
      id: 2,
      title: 'MVP Build & Launch',
      description: [
        'Bring your product to market on time and on budget.',
        'Our approach sets a clear budget, timeline, and scope to bring v1 to market.',
      ],
      ref: journeyRef2,
    },
    {
      id: 3,
      title: 'Early User Acquisition',
      description: [
        'Flexible as-needed design & development to support early adopter feedback, all with the comfort of robust 24/7 support.',
        'Our approach allows you to focus on user acquisition while responding quickly to key growth opportunities and early adopter feedback. We provide flexible access to our dev environment with no monthly commitment.',
      ],
      ref: journeyRef3,
    },
    {
      id: 4,
      title: 'Grow & Scale',
      description: [
        'Turn-key scalability for when you’re ready to ramp up. Our dedicated team can provide support from our experience in guiding other products to scale.',
        'Our approach allows you to quickly scale when the time is right. From advanced experimentation, support for engagement campaigns, robust analytics, accelerated design & development, to scaling server architectures, we’re ready to support you.',
      ],
      ref: journeyRef4,
    },
  ];

  const returnSelectedJourneyStep = useMemo(() => {
    return journeyStepsTexts[journeyStep - 1] ?? undefined;
  }, [journeyStep]);

  return (
    <StickyWrapper>
      {/* <FillerDiv/> */}
      <div id="product-journey-0" />
      <ProductJourneyDiv id="product-journey">
        <JourneyTitle>Our Approach to Your Product Journey</JourneyTitle>
        {!!returnSelectedJourneyStep && (
          <JourneyDiv>
            <div>
              <StepInfoDiv>
                <StepNumber>{journeyStep}</StepNumber>
                <JourneyStepTitle>{returnSelectedJourneyStep.title}</JourneyStepTitle>
                <JourneyStepDescriptionPrimary>
                  {returnSelectedJourneyStep.description[0]}
                </JourneyStepDescriptionPrimary>
                <JourneyStepDescriptionSecondary>
                  {returnSelectedJourneyStep.description[1]}
                </JourneyStepDescriptionSecondary>
              </StepInfoDiv>
              <JourneyStepsDiv>
                {journeyStepsTexts.map((step: any, i) => (
                  <JourneyStepButton
                    href={`#product-journey-${i}`}
                    onClick={() => {
                      setPreviousJourneyStep(journeyStep);
                      setJourneyStep(step.id);
                    }}
                    selected={step.id === journeyStep}
                  />
                ))}
              </JourneyStepsDiv>
            </div>
            <RelativeDiv>
              <NewApproachGraphic step={journeyStep}/>
              {/* <ApproachGraphic step={journeyStep - 1 || 1} />
              <ApproachGraphic
                key={`journey-step-previous-img-${previousJourneyStep}`}
                previous
                step={previousJourneyStep}
              />
              <ApproachGraphic key={`journey-step-img-${journeyStep}`} step={journeyStep} /> */}
            </RelativeDiv>
          </JourneyDiv>
        )}
      </ProductJourneyDiv>
      {journeyStepsTexts.map((item) => (
        <PlaceholderDiv
          id={`product-journey-${item.id}`}
          ref={item.ref}
          key={`${item.id}-${item.title}`}
        />
      ))}
    </StickyWrapper>
  );
}

const StickyWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 60px;
  background-color: ${theme.WHITE_COLOR};
  @media screen and (max-width: 1366px) {
    padding-top: 140px;
  }
  /* @media ${desktopSize} {
    padding-top: 140px;
  } */
  @media ${mobileSize} {
    padding-top: 50px;
  }
`;

const ProductJourneyDiv = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* padding-top: 150px; */
  /* padding-bottom: 60px; */
  padding-left: 30px;
  padding-right: 30px;
  /* min-height: 1200px; */
  height: 100vh;
  background-color: ${theme.WHITE_COLOR};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${desktopSize} {
    padding-top: 0px;
    /* margin-top: -100px; */
  }
  @media ${laptopSize} {
    min-height: 100vh;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media ${mobileSize} {
    position: relative;
    height: max-content;
    padding-top: 32px;
  }
`;

const JourneyTitle = styled(NewAppTitle)`
  color: ${theme.MAIN_DARK_BLUE};
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  @media ${laptopSize} {
    font-weight: 800;
    text-align: center;
  }
`;

const JourneyDiv = styled.div`
  max-width: 1267px;
  min-height: 560px;
  display: grid;
  grid-template-columns: 460px 772px;
  grid-gap: 35px;
  @media ${desktopSize} {
    max-width: 100%;
    display: grid;
    grid-template-columns: 2fr 3fr;
  }
  @media ${laptopSize} {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    max-width: 100vw;
    /* padding: 30px; */
    width: 100%;
  }
`;

const StepInfoDiv = styled.div`
  width: 460px;
  max-width: 460px;
  margin: 0px;
  min-height: 550px;
  overflow: hidden;
  @media ${desktopSize} {
    width: 100%;
  }
  @media ${laptopSize} {
    width: 100%;
    max-width: 100%;
    margin: 0px;
    min-height: 0;
  }
`;

const StepNumber = styled.div`
  background: url(${numberHexagon});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 94px;
  width: 79px;
  font-weight: 700;
  font-size: 62px;
  line-height: 1;
  text-align: center;
  color: ${theme.WHITE_COLOR};
  font-family: ${theme.SECONDARY_FONT};
  padding-top: 22px;
  @media ${laptopSize} {
    display: none;
  }
`;

const JourneyStepTitle = styled(NewAppTitle)`
  margin: 0px;
  font-weight: 700;
  font-size: 30px;
  line-height: 46px;
  color: ${theme.MAIN_DARK_BLUE};
  margin-top: 32px;
  margin-bottom: 10px;
  @media ${laptopSize} {
    text-align: center;
    margin-top: 48px;
  }
`;

const JourneyStepDescriptionPrimary = styled(NewAppText)`
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
  color: ${theme.MAIN_DARK_BLUE};
  margin: 0px;
  margin-bottom: 22px;
  @media ${laptopSize} {
    text-align: center;
  }
`;

const JourneyStepDescriptionSecondary = styled(NewAppText)`
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: ${theme.MAIN_DARK_BLUE};
  margin: 0px;
  margin-bottom: 50px;
  @media ${laptopSize} {
    text-align: center;
  }
`;

const JourneyStepsDiv = styled.div`
  display: flex;
  @media ${laptopSize} {
    justify-content: center;

    /* margin-bottom: 30px; */
  }
`;

interface SelectedProp {
  selected: boolean;
}

export const JourneyStepButton = styled.a<SelectedProp>`
  width: 60px;
  height: 10px;
  background-color: ${(props) => (props.selected ? theme.SKY_BLUE : theme.GREY_BLUE)};
  margin-right: 14px;
  cursor: pointer;
  z-index: 6;
  transition: background-color 250ms ease-in-out;
  :hover {
    background-color: ${(props) => (props.selected ? theme.SKY_BLUE : theme.LIGHT_BLUE)};
  }
  @media ${mobileSize} {
    width: 100%;
  }
  :last-child {
    margin-right: 0px;
  }
`;

const PlaceholderDiv = styled.div`
  height: 60vh;
  width: 100vw;
  background-color: blue;
  @media ${mobileSize} {
    display: none;
  }
`;

const RelativeDiv = styled.div`
  position: relative;
  width: 95%;
  min-width: 354px;
  @media ${laptopSize} {
    margin: 0 auto;
    width: 380px;
    min-height: 220px;
  }
  @media ${mobileSize} {
    width: auto;
    height: 188px;
  }
`;
