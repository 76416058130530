import { motion } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import { laptopSize, mobileSize } from '../../utils';
import { theme } from '../../utils/theme';
import { NewAppText, NewAppTitle } from '../ui';
import { ProcessGraphic } from './ProcessGraphic';
import { JourneyStepButton } from './ProductJourney';

export default function DevProcess() {
  const [processStep, setProcessStep] = useState(1);
  const [previousProcessStep, setPreviousProcessStep] = useState(1);
  // const [processPaused, setProcessPaused] = useState(false);

  const { ref: processRef1, inView: processInView1 } = useInView();
  const { ref: processRef2, inView: processInView2 } = useInView();
  const { ref: processRef3, inView: processInView3 } = useInView();
  const { ref: processRef4, inView: processInView4 } = useInView();
  const { ref: processRef5, inView: processInView5 } = useInView();
  const { ref: processRef6, inView: processInView6 } = useInView();

  useEffect(() => {
    if (processInView1) {
      setPreviousProcessStep(processStep);
      setProcessStep(1);
    }
    if (processInView2) {
      setPreviousProcessStep(processStep);
      setProcessStep(2);
    }
    if (processInView3) {
      setPreviousProcessStep(processStep);
      setProcessStep(3);
    }
    if (processInView4) {
      setPreviousProcessStep(processStep);
      setProcessStep(4);
    }
    if (processInView5) {
      setPreviousProcessStep(processStep);
      setProcessStep(5);
    }
    if (processInView6) {
      setPreviousProcessStep(processStep);
      setProcessStep(6);
    }
  }, [
    processInView1,
    processInView2,
    processInView3,
    processInView4,
    processInView5,
    processInView6,
  ]);

  const processStepsTexts = [
    {
      id: 1,
      title: 'Research & Strategy',
      description:
        'Our first step is to understand your vision and goals. We immerse ourselves in your unique industry and analyze the data. Then we identify your product landscape and create actionable project plans and solution roadmaps.',
      ref: processRef1,
    },
    {
      id: 2,
      title: 'UX/UI Design',
      description:
        'Our UX/UI team works with you to design a polished app that is both beautiful AND functional. We create premium user experiences that boost your brand engagement and drive your customer lifetime value.',
      ref: processRef2,
    },
    {
      id: 3,
      title: 'Engineering',
      description:
        'We provide a tailored team of US-based engineers to develop the technology, platforms and services that will make your app a success. And we always build in plans for scalability and growth.',
      ref: processRef3,
    },
    {
      id: 4,
      title: 'Test & Validate',
      description:
        'Providing a secure digital experience that functions exceptionally well is always our goal. To that end, we perform extensive quality assurance testing prior to launch to ensure that the designs, functionality, and integrations are all implemented properly.',
      ref: processRef4,
    },
    {
      id: 5,
      title: 'Launch',
      description:
        'Once your digital product is ready for launch, Rapptr is by your side to take it live! We’ll handle the production deployment, be on standby to make sure everything runs smoothly, and be eagerly awaiting early adopter feedback just like you!',
      ref: processRef5,
    },
    {
      id: 6,
      title: 'Measure & Refine',
      description:
        'Sustainable long-term success means knowing your product is meeting your business objectives and goals. Using a data-driven agile process, we’ll continuously measure & optimize, and work with you proactively to continuously re-prioritize & execute your product’s roadmap.',
      ref: processRef6,
    },
  ];

  const returnSelectedProcessStep = useMemo(() => {
    return processStepsTexts[processStep - 1] ?? undefined;
  }, [processStep]);
  return (
    <StickyWrapper id="agile-development-0">
      <DevelopmentProcessContainer id="agile-development">
        <ProcessTitle>Our Agile Development Process</ProcessTitle>
        <ProcessSubheading>
          Our engagement methodology is supported by all facets of our agile development process.
        </ProcessSubheading>
        {!!returnSelectedProcessStep && (
          <ProcessStepDiv key={`process-step-${processStep}`}>
            {/* <RelativeDiv>
              <motion.div
                key={`process-step-img-previous-${previousProcessStep}`}
                initial={{
                  opacity: 1,
                }}
                animate={{
                  opacity: 0,
                }}
                transition={{ duration: 0.2, ease: 'easeInOut' }}
              >
                <ProcessGraphic step={previousProcessStep} />
              </motion.div>              
              <VisibleGraphic>
                <ProcessGraphic step={processStep} />
              </VisibleGraphic>
            </RelativeDiv> */}
            <ProcessGraphic step={processStep} prevStep={previousProcessStep} />
            <div>
              <StepInfoDiv2>
                <ProcessStepTitle>0{processStep}</ProcessStepTitle>
                <ProcessStepTitle>{returnSelectedProcessStep.title}</ProcessStepTitle>
                <ProcessStepDescription>
                  {returnSelectedProcessStep.description}
                </ProcessStepDescription>
                {processStep >= 1 && processStep < 6 && (
                  // <a href></a>
                  <NextText
                    onClick={() => {
                      document.getElementById(`agile-development-${processStep}`)?.scrollIntoView();
                      setPreviousProcessStep(processStep);
                      setProcessStep(processStep + 1);
                      // setProcessPaused(true);
                    }}
                  >
                    Next →
                  </NextText>
                )}
                {processStep === 6 && (
                  <NextText
                    onClick={() => {
                      document.getElementById('agile-development-0')?.scrollIntoView();
                      // setTimeout is for bug where 01 button would not show the first dev process
                      setTimeout(() => {
                        setPreviousProcessStep(processStep);
                        setProcessStep(1);
                      }, 50);
                      // setProcessPaused(true);
                    }}
                  >
                    ← Back to Start
                  </NextText>
                )}
              </StepInfoDiv2>
              <FlexDiv>
                {processStepsTexts.map((step: any, i) => (
                  <JourneyStepButton
                    href={`#agile-development-${i}`}
                    onClick={() => {
                      // setTimeout is for bug where 01 button would not show the first dev process
                      setTimeout(() => {
                        setPreviousProcessStep(processStep);
                        setProcessStep(step.id);
                      }, 15);
                      // setProcessPaused(true);
                    }}
                    selected={step.id === processStep}
                  />
                ))}
              </FlexDiv>
            </div>
          </ProcessStepDiv>
        )}
      </DevelopmentProcessContainer>
      {processStepsTexts.map((item, i) => (
        <PlaceholderDiv
          id={`agile-development-${item.id}`}
          ref={item.ref}
          key={`${item.id}-${item.title}`}
        />
      ))}
      <AbsDiv3 />
      <AbsDiv4 />
    </StickyWrapper>
  );
}

const StickyWrapper = styled.div`
  /* height: 5000px; */
  /* padding-top: 160px; */
  position: relative;
  background-color: ${theme.MAIN_DARK_BLUE};
`;

const DevelopmentProcessContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: -80px;
  min-height: 100vh;
  /* height: 100vh; */
  background-color: ${theme.MAIN_DARK_BLUE};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  margin-bottom: 200px;
  @media ${laptopSize} {
    /* min-height: fit-content;
    height: fit-content; */

    padding-top: 100px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 1024px) {
    position: relative;
    top: 0;
    height: auto;
    padding-top: 100px;
    margin-bottom: 0;
  }
`;

const ProcessTitle = styled(NewAppTitle)`
  color: ${theme.WHITE_COLOR};
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  @media ${laptopSize} {
    font-weight: 800;
    text-align: center;
  }
`;

const ProcessSubheading = styled(NewAppText)`
  font-weight: 300;
  font-size: 32px;
  line-height: 46px;
  text-align: center;
  max-width: 940px;
  margin-bottom: 62px;
  @media screen and (max-width: 1024px) {
    margin-bottom: 0;
    font-size: 21px;
    line-height: 32px;
  }
`;

const ProcessStepDiv = styled(motion.div)`
  display: grid;
  max-width: 1037px;
  /* min-height: 553px; */
  grid-template-columns: 4fr 5fr;
  grid-gap: 35px;
  @media screen and (max-width: 1024px) {
    max-width: 100%;
    padding: 30px;
    width: 100%;
    grid-template-columns: 1fr;
  }
`;

const StepInfoDiv2 = styled.div`
  width: 460px;
  min-height: 400px;
  @media screen and (max-width: 1024px) {
    width: 100%;
    min-height: 100%;
    max-width: 100%;
  }
`;

const RelativeDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 90%;
  @media ${laptopSize} {
    /* height: 500px; */
  }
  @media screen and (max-width: 1024px) {
    height: auto;
    margin: 0 auto;
  }
`;

const VisibleGraphic = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const ProcessStepTitle = styled(NewAppTitle)`
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  font-weight: 700;
  font-size: 30px;
  line-height: 46px;
  color: ${theme.WHITE_COLOR};
  @media screen and (max-width: 1024px) {
    text-align: center;
  }
`;

const ProcessStepDescription = styled(NewAppText)`
  font-weight: 300;
  font-size: 21px;
  line-height: 32px;
  color: ${theme.WHITE_COLOR};
  margin: 0px;
  margin-bottom: 22px;
  @media screen and (max-width: 1024px) {
    text-align: center;
  }
`;

const NextText = styled(NewAppText)`
  color: ${theme.SKY_BLUE};
  cursor: pointer;
  line-height: 1;
  user-select: none;
  @media screen and (max-width: 1024px) {
    text-align: center;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const AbsDiv3 = styled.div`
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 400px 30vw;
  border-color: transparent transparent ${theme.WHITE_COLOR} transparent;
  bottom: 0px;
  right: 0;
  z-index: 3;
  @media ${laptopSize} {
    display: none;
  }
`;

const AbsDiv4 = styled.div`
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 200px 0 0 70vw;
  border-color: transparent transparent transparent ${theme.WHITE_COLOR};
  bottom: 0px;
  left: 0;
  z-index: 3;
  @media ${laptopSize} {
    border-width: 0 100vw 50px 0;
    bottom: -49px;
    border-color: transparent ${theme.MAIN_DARK_BLUE} transparent transparent;
  }
`;

const PlaceholderDiv = styled.div`
  height: 60vh;
  width: 100vw;
  /* background-color: ${theme.MAIN_DARK_BLUE}; */
  background-color: ${(props) => props.color};
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
