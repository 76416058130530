import React from 'react';
import styled from 'styled-components';
import GraphParameters from '../../images/approach/product-journey/Product Journey_ Graph Paramerters.svg';
import activeStep1 from '../../images/approach/product-journey/_Active/PJ_Stategy & Design_Active.svg';
import activeStep2 from '../../images/approach/product-journey/_Active/PJ_MVP Build & Launch_Active.svg';
import activeStep3 from '../../images/approach/product-journey/_Active/PJ_Early User Acquisition_Active.svg';
import activeStep4 from '../../images/approach/product-journey/_Active/PJ_Grow & Scale_Active.svg';
import staticStep2 from '../../images/approach/product-journey/_Static/PJ_MVP Build & Launch_Static.svg';
import staticStep3 from '../../images/approach/product-journey/_Static/PJ_Early User Acquisition_Static.svg';
import staticStep4 from '../../images/approach/product-journey/_Static/PJ_Grow & Scale_Static.svg';

interface StepImgProps {
  activeSrc: string;
  staticSrc: string;
  alt: string;
  active: boolean;
}

const StepImg: React.FC<StepImgProps> = ({ activeSrc, staticSrc, alt, active }) => {
  return (
    <StepContainer>
      <Img src={activeSrc} alt={alt} active={active}/>
      <Img src={staticSrc} alt={alt} active={!active}/>
    </StepContainer>
  );
};

const StepContainer = styled.div`
  display: flex;
  align-items: start;
  position: relative;
  width: 25%;
  height: 100%;
`;

const Img = styled.img<{ active: boolean }>`
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: ${props => props.active ? 1 : 0};
  transition: opacity 0.5s ease-in-out;
`;

// ========================================================================================

interface Props {
  step: number;
  previousStep?: number;
}

const STEPS = [
  {
    activeSrc: activeStep1,
    alt: 'Strategy & Design',
  },
  {
    activeSrc: activeStep2,
    staticSrc: staticStep2,
    alt: 'MVP Build & Launch',
  },
  {
    activeSrc: activeStep3,
    staticSrc: staticStep3,
    alt: 'Early User Acquisition',
  },
  {
    activeSrc: activeStep4,
    staticSrc: staticStep4,
    alt: 'Grow & Scale',
  },
];

export const NewApproachGraphic: React.FC<Props> = ({ step }) => {
  return (
    <Container>
      <AxisImg src={GraphParameters} alt="graph parameters" />
      <Steps>
        {STEPS.map((item, i) => (
          <StepImg
            key={item.alt}
            activeSrc={item.activeSrc}
            staticSrc={item.staticSrc}
            alt={item.alt}
            active={step >= i + 1}
          />
        ))}
      </Steps>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  @media screen and (max-width: 385px) {
    width: 90%;
  }
`;

const AxisImg = styled.img``;

const Steps = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 5%;
  bottom: 8%;
  display: flex;
  align-items: flex-end;
  gap: 4px;
  width: 90%;
`;
